
/*@font-face { font-family: "GTWalsheimBold";
    src: url("public/assets/fonts/GTWalsheimBold.eot"); !* IE9*!
    src: url("public/assets/fonts/GTWalsheimBold.eot?#iefix") format("embedded-opentype"), !* IE6-IE8 *!
    url("public/assets/fonts/GTWalsheimBold.woff2") format("woff2"), !* chrome、firefox *!
    url("public/assets/fonts/GTWalsheimBold.woff") format("woff"), !* chrome、firefox *!
    url("public/assets/fonts/GTWalsheimBold.ttf") format("truetype"), !* chrome、firefox、opera、Safari, Android, iOS 4.2+*!
    url("public/assets/fonts/GTWalsheimBold.svg#GTWalsheimBold") format("svg"); !* iOS 4.1- *!
}

@font-face { font-family: "GTWalsheimMedium";
    src: url("public/assets/fonts/GTWalsheimMedium.eot"); !* IE9*!
    src: url("public/assets/fonts/GTWalsheimMedium.eot?#iefix") format("embedded-opentype"), !* IE6-IE8 *!
    url("public/assets/fonts/GTWalsheimMedium.woff2") format("woff2"), !* chrome、firefox *!
    url("public/assets/fonts/GTWalsheimMedium.woff") format("woff"), !* chrome、firefox *!
    url("public/assets/fonts/GTWalsheimMedium.ttf") format("truetype"), !* chrome、firefox、opera、Safari, Android, iOS 4.2+*!
    url("public/assets/fonts/GTWalsheimMedium.svg#GTWalsheimMedium") format("svg"); !* iOS 4.1- *!
}*/

@font-face { font-family: "GTWalsheimLight";
    src: url("/public/assets/fonts/GTWalsheimLight.eot"); /* IE9*/
    src: url("/public/assets/fonts/GTWalsheimLight.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/public/assets/fonts/GTWalsheimLight.woff2") format("woff2"), /* chrome、firefox */
    url("/public/assets/fonts/GTWalsheimLight.woff") format("woff"), /* chrome、firefox */
    url("/public/assets/fonts/GTWalsheimLight.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("/public/assets/fonts/GTWalsheimLight.svg#GT Walsheim Light") format("svg"); /* iOS 4.1- */
}

html {
    box-sizing: border-box;
    font-size: 62.5%; /* 1rem = 10px */
    background: rgb(244, 244, 244);
}

body {
    box-sizing: border-box;
    font-size: 1.6rem; /* 1.6rem = 16px */
    overflow-x: hidden;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

* { padding: 0; margin: 0; }
a { text-decoration: none; }
button { border: none; }

ul {
    list-style: none;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
} li { padding-bottom: 10px; }
  li:last-child { padding-bottom: 0; }

  .cerr {
      display: none;
  }
