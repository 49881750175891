
#page-wrap { /* reserve space for menu */
    text-align: center;
    overflow: auto;
    /*top: 45%;*/
    position: relative;
    z-index: 8;
        width: 100%;
        height: 70px; /* root */
  /*  background: burlywood; */
}

.d { /* menu */
    position: fixed;
    z-index: 7;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
        width: 100%;
        height: 70px; /* root */
    box-sizing: border-box;
    /*border: solid 1px red;*/
    padding-right: 30px;
    padding-left: 30px;
   /* opacity: 0.7;*/
    background: rgb(244, 244, 244); /* root */
    box-shadow: rgba(176, 176, 176, 0.3) 0px 2px 15px 0px;
    //border: solid 1px red;
}

s. {
    width: 30px;
    height: auto;
    border: solid 1px black;
}

.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    //margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

.bm-item:hover {
    color: white;
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 20px;
    right: 36px; /* root */
    top: 25px;

}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;

}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
   /* top: 20px;
   right: 20px;*/
}

/* General sidebar styles */
.bm-menu {
   /* display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;*/
    background: #112734;
    /*padding: 2.5em 1.5em 0;*/
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
